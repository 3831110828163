/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p",
    a: "a",
    pre: "pre",
    code: "code",
    strong: "strong"
  }, _provideComponents(), props.components), {Aside} = _components;
  if (!Aside) _missingMdxReference("Aside", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    id: "day-6-tuning-trouble"
  }, "Day 6: Tuning Trouble"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://adventofcode.com/2022/day/6"
  }, "https://adventofcode.com/2022/day/6")), "\n", React.createElement(Aside, null, React.createElement(_components.p, null, "TL;DR: ", React.createElement(_components.a, {
    href: "https://github.com/NickyMeuleman/scrapyard/blob/main/advent_of_code/2022/src/day_06.rs"
  }, "my solution in\nRust"))), "\n", React.createElement(_components.p, null, "Today we get a communication device from an elf."), "\n", React.createElement(_components.p, null, "A stream of characters get sent to the device.\nYour input is the stream it receives."), "\n", React.createElement(_components.p, null, "An example input looks like this:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-txt",
    title: "input.txt"
  }, "mjqjpqmgbljsphdztnvjfqwrcgsmlb\n")), "\n", React.createElement(_components.h2, {
    id: "part-1"
  }, "Part 1"), "\n", React.createElement(_components.p, null, "The start of a ", React.createElement(_components.strong, null, "packet-marker"), " is the first sequence of ", React.createElement(_components.strong, null, "4"), " characters that are all different."), "\n", React.createElement(_components.p, null, "The question asks to find how many characters had to be checked when we detect the first marker."), "\n", React.createElement(_components.p, null, "I looped over sliding windows of size 4.\nFor each window, I check if all characters in it are unique."), "\n", React.createElement(_components.p, null, "To check if all characters in a window are unique,\nI loop through the window and check if the character at that point exists in the window so far."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "window\n    .iter()\n    .enumerate()\n    .all(|(idx, c)| !window[..idx].contains(c))\n")), "\n", React.createElement(Aside, {
    variant: "info"
  }, React.createElement(_components.p, null, "I could have also checked for uniqueness differently:\nIf ", React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Set_(abstract_data_type)"
  }, "a set"), " of a window has the same length as the window itself, all items in that window are unique."), React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "window.iter().collect::<HashSet<char>>().len() == window.len()\n"))), "\n", React.createElement(_components.p, null, "The first window that passes that check is the first marker."), "\n", React.createElement(_components.p, null, "The index of that window is the beginning of that 4 character marker."), "\n", React.createElement(_components.p, null, "Add 4 to that index to find how many characters have been received up to that point."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "day_06.rs"
  }, "pub fn part_1() -> usize {\n    let input = std::fs::read_to_string(\"src/day06.txt\").unwrap();\n\n    input.as_bytes()\n        .windows(4)\n        .position(|window| {\n            window\n                .iter()\n                .enumerate()\n                .all(|(idx, c)| !window[..idx].contains(c))\n        })\n        .unwrap()\n        + 4\n}\n")), "\n", React.createElement(_components.h2, {
    id: "part-2"
  }, "Part 2"), "\n", React.createElement(_components.p, null, "The start of a ", React.createElement(_components.strong, null, "message"), " is the first sequence of ", React.createElement(_components.strong, null, "14"), " characters that are all different."), "\n", React.createElement(_components.p, null, "The question asks to find how many characters had to be checked when we detect the first message."), "\n", React.createElement(_components.p, null, "This code is identical to part1, but that constant of 4 is now 14."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "day_06.rs"
  }, "pub fn part_2() -> usize {\n    let input = std::fs::read_to_string(\"src/day06.txt\").unwrap();\n\n    input.as_bytes()\n        .windows(14)\n        .position(|window| {\n            window\n                .iter()\n                .enumerate()\n                .all(|(idx, c)| !window[..idx].contains(c))\n        })\n        .unwrap()\n        + 14\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
